@tailwind base;
@tailwind components;
@tailwind utilities;

@import './globals.css';

body {
  margin: var(--spacing-lg) var(--spacing-md);
  background-color: var( --color-background);
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


button{
  @apply py-2 px-4 rounded-lg shadow-md focus:outline-none transition duration-300 ease-in-out;
  @apply w-4/5 sm:w-auto;
  background-color: white;
}

button:hover{
  background-color: var(--color-accent);
  color: white;
}

.p3logo{
  position: absolute;
  right: var(--spacing-md);
  top: var(--spacing-md);
  width: 60px;
  z-index: -100;
  opacity: 0.2;
  @apply md:opacity-100;
}

.question{
  max-width: 90vw;
  width: 90vw;
  display: flex;
  flex-direction: column;
  margin: var(--spacing-md);
  animation: slide-in 0.2s ease-out;

  @apply md:max-w-[61.8vw] md:w-[61.8vw];
}

.question--ignored{
  opacity: 0.3;
  transition: opacity 0.2s;  
}

.question--note{
  animation: slide-in 0.2s ease-out;
  height: min(600px,12vh);

  .textarea{
    @apply mt-[var(--spacing-sm)] p-[var(--spacing-sm)] block w-full border rounded-sm shadow-sm focus:outline-none sm:text-sm;
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.answer{
  display: flex;
  flex-direction: column;

  .answer--main{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .answer--text{
    padding-right: var(--spacing-sm); /* put small space between answer text and checkboxes */
  }

  .answer--info-icon{
    margin: 0 var(--spacing-sm);
    color: var(--color-gray);
  }

  .answer--additionalInformation{
    animation: slide-in 0.2s ease-out;
    color: var(--color-accent);
  }
}



.answers{
  margin-top: var(--spacing-md);
  gap: var(--spacing-md);
  justify-content: space-evenly;
}

.domainNavigation{
  gap: var(--spacing-md);
  @apply flex md:flex-wrap flex-col md:flex-row justify-center space-x-2;
}

.row{
  display: flex;
  flex-direction: row;
}

.col{
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
}

.auditQuestionaire{
  width: 90vw;
}

.question--actions{
  /* e.g. ignore question / add note */
  color: var(--color-gray);
  display: flex;
  flex-direction: column;
  align-items: first baseline;
  @apply space-y-[var(--spacing-sm)] text-sm md:flex-row md:space-x-4;
}

.form-checkbox{
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  accent-color: var(--color-accent);
}

.auditResultSideBar{
  position: fixed;
  right: 0;
  margin-top: var(--spacing-md);
  @apply xl:mr-[10vw] lg:mr-[var(--spacing-xl)];

  .doughnut{
    width: 240px;
    height: 240px;
  }
}

.summary{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @apply md:m-16;

  .doughnut{
    width: 200px;
    height: 200px;
  }

  .result--section{
    min-width: 80vw;
    width: 80px;
    animation: slide-in 0.2s ease-out;
    @apply md:min-w-[24vw] p-6 m-4 text-center rounded-lg shadow-lg bg-white;
  }
}

