:root {
    /* Spacing Variables */
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 64px;

    /* Color Variables */
    --color-primary: #1a73e8;
    --color-accent:  rgb(25,60,110);
    --color-black: #000000;
    --color-gray: #808080;
    --color-light: #f5f5f5;
    --color-background: #edecf5;

    /* Font Size Variables */
    --font-size-xs: 0.75rem; /* 12px */
    --font-size-sm: 0.875rem; /* 14px */
    --font-size-md: 1rem; /* 16px */
    --font-size-lg: 1.25rem; /* 20px */
}
